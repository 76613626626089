

.fc-body
{
  background-color: #ECE4C6 !important
}

.fc-nonbusiness
{
  opacity:1;
}

.pagination {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media (max-width: 480px) {
  .pagination {
    margin-top: 50px;
  }
}
.pagination-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination-btns {
  border: 1px solid #ddd;
  padding: 0.5em 0.75em;
  font-weight: 500;
  color: #2b2e34;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
@media (max-width: 480px) {
  .pagination-btns {
    font-size: 11px;
  }
}
.pagination-btns:hover {
  color: #ffffff;
  background-color: #37a2d0;
  border-color: #37a2d0;
  text-decoration: none;
}
.pagination-active {
  color: #ffffff;
  background-color: #37a2d0;
}
.pagination-next {
  cursor: pointer;
  transition: 0.3s;
  padding: 0.5em 0.75em;
  border: 1px solid #ddd;
  text-decoration: none;
  color: #000;
  text-decoration: none !important;
}
.pagination-next:hover {
  color: #ffffff;
  background-color: #37a2d0;
  border-color: #37a2d0;
  text-decoration: none !important;
}
@media (max-width: 480px) {
  .pagination-next {
    padding: 10px;
    text-decoration: none !important;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.combobox-input {
  border-color: rgb(159, 159, 159) !important;
  padding: 15px 12px !important;
  width: 100% !important;
  text-align: left !important;
  font-family: inherit !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #071d27 !important;
  outline: none !important;
}

.combobox-list {
  background: #ffffff !important;
  border: 1px solid #dfe5e8 !important;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.14) !important;
  border-radius: 4px !important;
  padding: 15px !important;
  margin-left: 15px !important;
  position: relative !important;
  z-index: 50000 !important;
}

.fc-event-danger-color {
  color: #FFA500;
}


.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}

.selected-card {
  border: 2px solid #37a2d0; /* Change 'red' to your desired border color */
}

.selected-radio-button {
  width: 20px;
  border-radius: 2px;
  text-align: center; 
}
.selected-radio-button-main {
  width: 40px;
  border: 1px solid #c6c6c6;
  border-radius: 15px;
  text-align: center; 
  display: flex;
  justify-content: center;
  margin: 5px;
}